:root {
  --font-fam: 'Outfit', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  --bg-colour: white;
  --foreground: #feba64;
  --highlights: #f9f871;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-colour);
  min-height: 100vh;
  font-family: var(--font-fam);
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
  background-color: var(--bg-colour); /* Background color of the header */
  position: sticky; /* Makes the header stay at the top */
  top: 0;
  z-index: 1000; /* Ensures the header is on top of other elements */
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem; /* Adjust as necessary */
}

.nav {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-right: 20px;
}

.nav-item:last-child {
  margin-right: 0;
}
.nav-item .nav {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-item .nav:hover,
.nav-item .nav.active {
  color: #333333;  /* Dark grey */
  text-decoration: none;
}

.button-59 {
  align-items: center;
  background-color: #fff;
  border: 2px solid #000;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -0.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-59:focus {
  color: #171e29;
}

.button-59:hover {
  border-color: #06f;
  color: #06f;
  fill: #06f;
}

.button-59:active {
  border-color: #06f;
  color: #06f;
  fill: #06f;
}

@media (min-width: 768px) {
  .button-59 {
    min-width: 170px;
  }
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: none;
  align-items: center;
}

fieldset {
  border: none;
}



.password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: clamp(1rem, 3vw, 1.5rem);
  border: 2px solid black;
  margin: 1rem;
  min-height: clamp(4rem, 50vw, 7rem);
  position: relative;
  min-width: clamp(22rem, 50vw, 40rem);
  padding-right: 1rem;
  padding-left: 1rem;
}

.flexbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

input {
  margin: 0.5rem;
}

.choices {
  margin-left: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-auto-rows: minmax(1rem, auto);
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  height: auto;
}

.grid > * {
  grid-column: span 2;
}

.radio {
  text-align: left;
  align-items: flex-start;
}

.customPw {
  height: 1rem;
}

section {
  display: flex;
  flex-direction: column;
  margin: 2rem auto; /* This centers the section horizontally */
  padding: 1rem;
  width: 100%;
  max-width: 85%;
  box-sizing: border-box;
}

section h1, h2, 
section p {
  width: 80%;
  text-align: center;
}

.charCount {
  font-size: clamp(0.3rem, 2vw, 0.8rem);
  padding-right: 1rem;
  max-height: 1rem;
  z-index: 10;
  position: absolute;
  bottom: 0;
}

.password h3 {
  align-self: center;
  justify-self: center;
  z-index: 0;
  font-weight: 200;
  margin-right: 2rem;
}

.reloadIcon {
  position: absolute;
  /* margin-top: clamp(1rem, 2vw, 3rem);
  margin-bottom: clamp(1rem, 2vw, 3rem); */
  right: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.textInput {
  border: 1px solid black;
  padding: 1rem;
  min-width: 15rem;
}

aside {
  text-align: left;
  font-weight: 200;
  margin: 1rem;
  padding: 1rem;
  line-height: 1.5rem;
  font-size: 0.8rem;
}

.error {
  height: 0.8rem;
}

.ttt table, td, tr {
  border: #000 solid 1px;
  margin: .3rem;
}

td {
  padding: clamp(1.5rem, 2vw + 1rem, 3rem);
  width: 4rem;
  height: 4rem;
  text-align: center;
  font-size: clamp(2rem, 2vw + 1rem, 3rem);
  border-style: dotted;
  border-color: #d3d3d3;
}

table {
  border: solid 2.5px;
  padding: 0;
}

.win {
  color: #06f;
}

.tictactoe svg {
  margin: .5rem;
}

.tictactoe {
  cursor: pointer;
}

.winner {
  height: 1rem;
  margin-top: 10px;
}

details[open] summary ~ * {
  animation: open 0.3s ease-in-out;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

details summary::-webkit-details-marker {
  display: none;
}

details {
  margin-top: 2rem;
}

details summary {
  width: 100%;
  position: relative;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 300;
  list-style: none;
}

details summary:after {
  content: "+";
  color: black;
  position: absolute;
  font-size: 1.75rem;
  line-height: 0;
  right: 0;
  font-weight: 200;
  transform-origin: center;
  transition: 200ms linear;
}

details[open] summary:after {
  transform: rotate(45deg);
  font-size: 2rem;
}

details summary {
  outline: 0;
}

details li {
  font-size: 0.95rem;
  margin: 0 0 1rem;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-trigger {
  background: none;
  border: none;
  cursor: pointer;
  font-family: var(--font-fam);
  font-size: inherit;
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.dropdown-trigger:hover {
  color: #333333;
}

.nav-item.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--bg-colour);
  border: 2px solid #000;
  padding: 0.5rem 0;
  list-style: none;
  min-width: 160px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.nav-item.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu li {
  padding: 0.25rem 1rem;
}

.dropdown-menu a {
  color: black;
  text-decoration: none;
  display: block;
  transition: color 0.3s ease;
}

.dropdown-menu a:hover {
  color: #333333;
}
.logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 10rem;
  width: 100%;
}

.logos a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logos svg {
  width: 1.5rem;
  height: 1.5rem;
}